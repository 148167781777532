import React, { useContext } from "react";
import { Link } from "gatsby";
import Text from "../components/Text";
import dokiImg from "../assets/images/dokidoki.webp";
import { LangContext } from "../context";
import { LangPrefix } from "../enums";

const NotFound = () => {
  const { langPrefix } = useContext(LangContext);
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center space-y-5">
      <div
        className="w-full bg-cover"
        style={{ backgroundImage: `url(${dokiImg})`, paddingTop: "40%" }}
      />
      <Text.Title className="text-5xl text-black-custom text-center">
        {langPrefix === LangPrefix.Ko
          ? "존재하지 않는 페이지입니다"
          : "Page Not Found"}
      </Text.Title>
      <Link to={`${langPrefix}/`}>
        <Text.Heading className="underline text-lg text-black-custom">
          {langPrefix === LangPrefix.Ko
            ? "홈으로 돌아가기"
            : "Walkthrough Home"}
        </Text.Heading>
      </Link>
    </div>
  );
};

export default NotFound;
